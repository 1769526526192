// 门禁状态
export const doorStatusOptions = [
  { label: "离线", value: 0, color: "gray" },
  { label: "在线", value: 1, color: "green" },
];

// 权限身份
export const doorRoles = [
  { label: "管理员", value: 1 },
  { label: "常驻人员", value: 2 },
  { label: "临时人员", value: 3 },
];

// 门禁类型
export const doorTypeOptions = [
  { label: "一码通", value: 1 },
  { label: "人脸", value: 2 },
];
